.ml-left-12 {
  margin-left: 12px;
}
.css-1u9des2-indicatorSeparator {
  display: none !important;
}
.css-13cymwt-control {
  background: #f4f7fa !important;
}
.selectsubcategory .ant-select-selector {
  height: 41px !important;
  background: #f4f7fa !important;
}
.selectsubcategory
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 39px !important;
  color: black;
}
.ml-right {
  margin-right: 15px;
}

.react-stars-wrapper-0028287394932299703 {
  position: absolute !important;
}

/* chat module styling start */
.chat-container {
  border: 2px solid rgb(116, 116, 116);
  border-radius: 10px;
}

.user-text-left .dropdown-toggle::after {
  display: none !important;
}

.user-text-left .dropdown-menu {
  padding: 7px 0;
}

/*  
.user-text-left .dropdown-menu.show {
    display:none;
} */

/* .user-text-left .drop-menu {
    display: none !important;
} */

.user-text-left .btn {
  padding: 0px 6px;
  border-radius: 0.25rem;
  font-size: 14px;
  margin-bottom: 0px;
  margin-right: 0px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.new-order {
  background-color: #afe1af;
}

.new-order:hover {
  background-color: #50c878 !important;
}

.pcoded-header .navbar-nav {
  display: flex !important;
}

.notif {
  float: right;
  margin-top: 22px;
  position: relative;
  display: block;
  width: 25px;
  font-size: 27px;
  /* height: 40px !important; */
  background-size: contain;
  text-decoration: none;
}

.num {
  position: absolute;
  top: -11px;
  left: 16px;
  color: red;
  font-size: 20px;
  position: absolute;
  top: -10px;
  left: 14px;
  color: black;
  border-radius: 50%;
  height: 19px;
  width: 19px;
  background-color: #b7ef8c;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px -1px;
  margin-right: 1px;
  font-size: 14px;
  margin-top: 1px;
}

.pl-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.chat_textbox {
  padding: 10px 50px !important;
}

.chat-unread-count {
  float: right;
  background-color: blue;
  color: white;
  border-radius: 30px;
  width: 25px;
  height: 25px;
  text-align: center;
  vertical-align: middle;
  /* padding-left: 3px; */
  padding-top: 2px;
  margin-top: -16px;
}

.email-phonenumber {
  width: 100%;
  justify-content: flex-end;
}

.chat_img {
  float: left;
  height: 42px;
  width: 42px;
}

.recent-chat-item {
  transition: transform 0.2s;
  /* Animation */
}

.recent-chat-item:hover {
  transform: scale(1.1);
  /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.chat_imged {
  /* float: right; */
  height: 50px;
  width: 50px;
  margin-right: 10px;
}
.chat_ib {
  /* float: left; */
  padding: 0 0 0 58px;
  /* width: 88%; */
}

.chat_ib h4 {
  font-size: 16px;
  /* margin-top: -5px; */
  font-weight: bold;
}

.chat_ib p {
  font-size: 16px;
  color: #666666;
  margin: auto;
  /* margin-top: -5px; */
}

.top-users {
  background-color: #f5f5f5;
  height: 9.4%;
  top: 0;
  position: sticky;
  width: 100%;
}

.username {
  font-weight: bold;
  color: black;
  font-size: 18px;
  width: 100%;
}

.text-for-email {
  font-weight: bold;
  color: black;
  margin-top: 7px;
  font-size: 16px;
}

.list-group-item {
  background: rgb(250, 250, 250) !important;
  position: relative;
  display: block;
  /* padding: 19px 0px 55px 13px !important; */
  margin-bottom: -1px;
  background-color: #fff;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important; */
  /* border: none !important; */
  /* border-radius: 10px !important; */
}

.send_btn_custome img {
  width: 35px;
  height: 35px;
  padding: 2px;
  border-radius: 50%;
}

.send_btn_custome {
  margin-right: 0px;
  margin-top: -57px;
  cursor: pointer;
}

.type_msg {
  border-top: 1px solid #c4c4c4;
  bottom: 10px;
  position: sticky;
}

.text-right {
  text-align: right !important;
}

.image_btn_custome {
  margin-right: 0px;
  margin-top: -50px;
  width: 0px;
}

.list-group-item-avtive {
  /* background:  rgb(255, 255, 255) !important; */
  position: relative;
  display: block;
  /* padding: 19px 0px 55px 13px !important; */
  margin-bottom: -1px;
  background-color: #ededed !important;
  border: 1px solid #dbdbdb;
  color: white !important;
}

.user-module {
  margin-top: 5px !important;
  margin-right: 7px;
}

.list-username {
  font-size: 18px;
  font-weight: bold;
  color: black;
  padding: 5px 0px;
}

.list-usertype {
  font-size: 15px;
  color: rgb(42, 42, 42);
}

.user-text-left {
  /* border: px solid gray; */
  border-radius: 10px;
  padding: 10px;
  color: rgb(16, 64, 146);
  background: #dcf8c6;
  font-weight: 600;
  font-size: 16px;
}

.user-text {
  /* border: 1px solid gray; */
  border-radius: 10px;
  padding: 10px;
  background: rgb(16, 64, 146);
  color: white;
  font-weight: 600;
  font-size: 16px;
}

.set-dateuser {
  display: block;
}

.left-msg-container {
  display: flex;
  justify-content: flex-end;
  /* align-items: end; */
  /* width: 50%; */
}

.chat_textbox .form-control::placeholder {
  padding-left: 15px;
}

/* 
.form-control:value{
    padding-left:15px;
} */

.right-msg-container {
  display: flex;
  justify-content: flex-start;
}

.user-name-top {
  font-size: 20px;
  background-color: #f4f4f4;
  font-weight: 700;
  border-radius: 10px;
}

.chat-input-text {
  padding: 9px;
  border-radius: 10px;
  width: 90%;
  background: #f4f4f4;
  border: 2px solid #dbdbdb;
}

.btn-send {
  padding: 8px 10px;
  background: rgb(16, 64, 146);
  color: white;
  font-size: 16px;
  font-weight: 600;
  border: none;
  border-radius: 10px;
}

.msg-avl-container {
  height: 515px;
  min-height: 515px;
  overflow: auto;
}

.no-msg-avil-container {
  height: auto;
}

.select-chat-style {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 21px;
  font-weight: 600;
  color: black;
}

.user-container {
  padding-right: 0px !important;
  border-right: 1px solid black;
}

.send-icon {
  height: 40px;
  width: 40px;
}

/* chat module styling end */

input[type="radio"] {
  display: inline;
}

@media (min-width: 576px) {
  .custom-modal-style.modal-dialog {
    max-width: 804px !important;
    margin: 1.75rem auto;
  }
}

.search-close-btn {
  font-size: 20px;
  top: 12px;
  right: 12px;
}

.multi-preview img {
  float: left;
  margin-left: 20px;
}

.search-inputed {
  width: 195px !important;
  border-radius: 25px !important;
}

.err-msg-creatui {
  color: red;
  font-size: 12px;
  margin-left: -128px !important;
}

textarea {
  resize: none !important;
}

input[type="file"] {
  color: transparent;
}

.srv-validation-message {
  /* margin-left:134px !important; */
}

*/ .pcoded-navbar .pcoded-inner-navbar li > a:hover {
  background: #ededed;
}

.pcoded-navbar .pcoded-inner-navbar li > a.active {
  background: #ededed;
  border-left: 4px solid blue;
}

.breadcrumb {
  background-color: white !important;
}

.search-input {
  width: 250px !important;
  border-radius: 25px !important;
}

.btn-primary:hover {
  color: #fff;
  background-color: #1e16f6 !important;
  border-color: #1e16f6 !important;
}

.imagstys {
  margin-top: -1px;

  height: 67px;
  margin-left: 16px;
}

.cke_bottom {
  padding: 6px 8px 2px;
  position: relative;
  border-top: 1px solid #d1d1d1;
  background: #f8f8f8;
  display: none !important;
}

/* .cke_reset {
    margin: 0;
    padding: 0;
    border: 0;
    background: transparent;
    text-decoration: none;
    width: auto !important;
    height: auto;
    vertical-align: baseline;
    box-sizing: content-box;
    position: static;
    transition: none;
} */

.pcoded-navbar .header-logo {
  background-color: blue !important;
}

.pcoded-header .dropdown.drp-user.show:before {
  color: blue !important;
}

.pcoded-navbar .header-logo + .datta-scroll {
  float: left;
  display: inline-block;
  background: white !important;
  color: black !important;
}

.pcoded-header .dropdown .profile-notification .pro-head {
  color: #fff;
  border-radius: 0.25rem 0.25rem 0 0;
  padding: 15px;
  position: relative;
  background: #1e16f6 !important;
}

.btn-main:hover {
  color: #fff;
  background-color: #47baeb !important;
  border-color: #47baeb !important;
}

.btn-main {
  color: #fff;
  background-color: #47baeb !important;
  border-color: #47baeb !important;
}

.card .card-header {
  background-color: transparent;
  border-bottom: 1px solid #f1f1f1;
  padding: 20px 25px;
  position: relative;
  color: black !important;
}

/* .auth-wrapper .auth-bg .r:first-child {
    top: -100px;
    right: -100px;
    background: -webkit-linear-gradient(225deg, #0F52BA 0%, #0080FF 100%) !important;
    background: linear-gradient(-135deg, #0F52BA -7%, #0080FF 23%) !important;
} */
.b-brand .b-bg {
  /* background: -webkit-linear-gradient(225deg, #1de9b6 0%, #1dc4e9 100%); */
  /* background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%); */
  /* border-radius: 10px; */
  /* width: 35px;
    height: 35px;
    display: inline-flex;
    align-items: center;
    justify-content: center; */
}

.b-brand .b-bgcolor {
  width: 35px;
  height: 35px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.auth-wrapper .auth-bg .r:first-child {
  top: -100px;
  right: -100px;
  background: linear-gradient(-135deg, #0f52ba -7%, #0080ff 23%) !important;
}

.auth-wrapper .auth-bg .r:last-child {
  left: -100px;
  bottom: -100px;

  background: linear-gradient(-135deg, #73c2fb 47%, #73c2fb -14%) !important;
}

.Order-details {
  font-size: 16px;
  font-weight: bold;
  margin-top: 19px;
}

.Comment-details {
  font-size: 16px;
  font-weight: bold;
  margin-top: 19px;
  margin-bottom: 13px;
  color: black;
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: none !important;
}

.ReactPlayer-video-edit {
  width: 94% !important;
  height: 165px !important;
}

.products-images {
  height: 101px !important;
  width: 152px !important;
}

.Listing-Product {
  height: 128px !important;
  width: 149px !important;
  margin-left: 69px !important;
}

.product-videos {
  height: 137px !important;
  width: 211px !important;
}

.auth-wrapper .auth-bg .r.s:nth-child(3) {
  left: -150px;
  bottom: 150px;
  background: #0f52ba !important;
}

.btn-primary {
  color: #fff;
  background-color: #1e16f6 !important;
  border-color: #1e16f6 !important;
}

.err-msg {
  color: red;
  font-size: 12px;
}

a {
  color: inherit !important;
}

.cke {
  width: 100% !important;
}

.feather {
  cursor: pointer;
}

.Toastify__toast--success {
  color: #07bc0c !important;
  background: white !important;
}

.Toastify__progress-bar--success {
  background: #07bc0c !important;
}

.Toastify__close-button--success {
  color: #07bc0c !important;
}

.Toastify__toast--error {
  color: #e74c3c !important;
  background: white !important;
}

.Toastify__progress-bar--error {
  background: #e74c3c !important;
}

.Toastify__close-button--error {
  color: #e74c3c !important;
}

.Toastify__toast--warning {
  color: #f1c40f !important;
  background: white !important;
}

.Toastify__progress-bar--warning {
  background: #f1c40f !important;
}

.Toastify__close-button--warning {
  color: #f1c40f !important;
}

.Toastify__toast--info {
  color: #3498db !important;
  background: white !important;
}

.Toastify__progress-bar--info {
  background: #3498db !important;
}

.Toastify__close-button--info {
  color: #3498db !important;
}

.active-btn {
  padding: 3px 10px;
}

.inactive-btn {
  padding: 3px 10px;
}

.err-msg-category {
  color: red !important;
  font-size: 13px !important;
  margin-left: 134px !important;
}

/* css for emoji picker  */

.picker-container {
  border: 1px solid black;
  display: flex;
  position: relative;
  padding-left: 8px !important;
}

.input-emoji-style {
  border: none !important;
  /* padding: 10px 0px !important; */
}

.textarea-emoji-icon {
  position: absolute;
  top: 21px;
  right: 3px;
}

/* css for wrapping reply column  */
.reply-wrap {
  white-space: pre-wrap !important;
}

/* css for reply modal */
.reply-modal.modal-dialog {
  max-width: 600px !important;
}

.err-msg-reply {
  color: red !important;
  font-size: 13px !important;
  margin-left: 160px !important;
  margin-top: 5px;
}

.backup-buttons {
  width: 100px;
  height: auto;
  color: white !important;
  padding: 8px !important;
  margin-top: 16px !important;
  margin-right: 10px !important;
}

.cke_notification.cke_notification_warning {
  display: none;
}
